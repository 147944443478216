import { useStaticQuery, graphql } from 'gatsby';

const useAllNewCarCampaignsQuery = (): GatsbyTypes.allNewCarCampaignsQuery => useStaticQuery<GatsbyTypes.allNewCarCampaignsQuery>(graphql`
  query allNewCarCampaigns {
    allMicrocmsNewcarCampaign {
      edges {
        node {
          id
          title
          url
          startDate
          endDate
        }
      }
    }
  }
`);

export default useAllNewCarCampaignsQuery;
